import cn from 'classnames';
import Link from 'next/link';
import React, { memo } from 'react';

import { Typography } from '@sravni/react-design-system/';
import { Button } from '@sravni/react-design-system/lib/Button';
import { Card } from '@sravni/react-design-system/lib/Card';

import { EmptyBoxIcon } from '@src/components/icons';
import { sendClickGetMoneyButtonEvent } from '@src/helpers/analyticsEvents';

import styles from './styles.module.scss';

export type Message = {
  title: string;
  message: string;
};

type Props = Message & {
  className?: string;
  buttonText?: string;
  backLinkRoute?: string;
};

const { Heading, Text } = Typography;

export const MessageBlock = memo(({ className, title, message, buttonText, backLinkRoute }: Props) => {
  const isButtonVisible = Boolean(buttonText) && Boolean(backLinkRoute);

  return (
    <Card className={cn(styles.wrapper, className)}>
      <div className={styles.content}>
        <EmptyBoxIcon className={styles.image} />
        <div className={styles.text}>
          <Heading level={4}>{title}</Heading>
          <Text size={14} className="h-color-D60">
            {message}
          </Text>
        </div>

        {isButtonVisible && (
          <Link href={backLinkRoute}>
            <Button
              block
              variant="primary"
              onClick={() => sendClickGetMoneyButtonEvent(`${window.location.origin}/zaimy/`)}
            >
              {buttonText}
            </Button>
          </Link>
        )}
      </div>
    </Card>
  );
});
