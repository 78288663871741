import pluralize from '@sravni/utils/lib/pluralize';

export const getReviewsCount = (reviewsCount: number | undefined) => {
  if (!reviewsCount) {
    return;
  }

  const reviewsPluralize = pluralize(reviewsCount, 'отзыв', 'отзыва', 'отзывов') ?? 'отзыв';
  return `${reviewsCount} ${reviewsPluralize}`;
};
