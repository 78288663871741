import cn from 'classnames';
import type { SyntheticEvent } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Icon } from '@sravni/react-design-system/lib/Icon';

import type { Document as IDocument } from '@src/@types/microcredits';
import DocumentIcon from '@src/components/icons/document';
import { sendDocumentsItemClickEvent } from '@src/helpers/analyticsEvents';

import styles from '../../styles.module.scss';

const { Link, Text } = Typography;

type Props = {
  documents?: IDocument[];
  eventLabel: string;
};

export const Documents = ({ documents = [], eventLabel }: Props) => {
  const handleItemClick = (event: SyntheticEvent, eventActionValue: string) => {
    event.stopPropagation();

    sendDocumentsItemClickEvent(eventLabel, eventActionValue);
  };

  return (
    <div className={cn(styles.twoColumnsWrapper, styles.oneRow)}>
      {documents.map(({ _id, src, name }) => (
        <div key={_id} className={styles.cardItemWithIcon}>
          <div className={styles.content}>
            <Text className="h-color-D60" size={12}>
              {name}
            </Text>
            {src && (
              <Link
                onClick={(event) => handleItemClick(event, name)}
                target="_blank"
                rel="noopener"
                className={styles.link}
                href={src}
              >
                <Icon icon={<DocumentIcon />} size={20} />
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
