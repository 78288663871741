import trim from 'lodash/trim';

import pluralize from '@sravni/utils/lib/pluralize';

import type * as ClientTypes from '@src/@types/clienttypes';
import type * as Dictionaries from '@src/@types/dictionary';
import { considerationTimeRange } from '@src/helpers/common';
import { printDictionaryFieldsMap } from '@src/helpers/dictionaries';

type TRow = [name: string, value: string] | undefined;

const benefitsBlackList = [
  'Для иностранных граждан',
  'Безработным',
  'Студентам',
  'Должникам',
  'Пенсионерам',
  'Для ИП',
  'Юридическим лицам',
  'Для бизнеса',
];

export const prolongationText = (prolongation: ClientTypes.IClientMicrocredit['prolongation']): TRow => [
  'Пролонгация',
  prolongation?.isAllowed ? 'да' : 'нет',
];

export const prolongationCountText = (prolongation: ClientTypes.IClientMicrocredit['prolongation']): TRow =>
  prolongation?.isAllowed && prolongation?.count ? ['Количество пролонгаций', String(prolongation.count)] : undefined;

export const postponementText = (postponement: ClientTypes.IClientMicrocredit['postponement']): TRow => {
  const value = (() => {
    if (!postponement?.isAllowed) {
      return 'нет';
    }
    if (postponement.count) {
      return `да, ${postponement.count} ${pluralize(postponement.count, 'раз', 'раза', 'раз')}`;
    }
    return 'да';
  })();

  return ['Отсрочка', value];
};

export const penaltyText = (penalty?: ClientTypes.IClientMicrocredit['penalty']): TRow =>
  penalty ? ['Штрафы', penalty] : undefined;

export const considerationTimeText = (
  considerationTime?: ClientTypes.IClientMicrocredit['considerationTime'],
): TRow => {
  if (!considerationTime) {
    return undefined;
  }

  return ['Рассмотрение заявки', considerationTimeRange(considerationTime, 'Быстро')];
};

export const registrationWayText = (
  dictFields: Record<Dictionaries.DictionaryValue<'registrationWay'>, string>,
  registrationWay?: ClientTypes.IClientMicrocredit['registrationWay'],
): TRow => {
  if (!registrationWay) {
    return undefined;
  }

  return ['Место заключения договора', printDictionaryFieldsMap(dictFields, registrationWay, ' / ')];
};

export const obtainingMethodText = (
  dictFields: Record<Dictionaries.DictionaryValue<'microCreditObtainingMethod'>, string>,
  obtainingMethod?: ClientTypes.IClientMicrocredit['obtainingMethod'],
): TRow => {
  if (!obtainingMethod) {
    return undefined;
  }

  return ['Выдача займа', printDictionaryFieldsMap(dictFields, obtainingMethod, ' / ')];
};

export const benefitsText = (benefits?: ClientTypes.IClientMicrocredit['benefits']): TRow => {
  const filteredBenefits = benefits?.filter((value) => !benefitsBlackList.includes(value)) || [];

  if (!filteredBenefits.length) {
    return undefined;
  }

  return ['Особенности и преимущества', filteredBenefits.join(', ')];
};

// TODO: request from https://github.com/sravni/credits-service/blob/bdf8acc9e4a60e872ee9c59ae23662fb62038ad1/src/handlers/v1/dictionaries/getDictionaries.ts#L27
const PAYMENT_TYPES = {
  ANNUITY: 'annuity',
  DIFFERENTIATED: 'differentiated',
};

const LABELS = {
  [PAYMENT_TYPES.ANNUITY]: 'Аннуитетные платежи',
  [PAYMENT_TYPES.DIFFERENTIATED]: 'Дифференцированные платежи',
};

export const paymentTypesText = (paymentTypes?: ClientTypes.IClientMicrocredit['paymentTypes']): TRow => {
  if (!paymentTypes?.length) {
    return;
  }

  return ['Тип платежей', paymentTypes.map((paymentType) => LABELS[paymentType]).join(', ')];
};

export const securityGuarantorText = (
  dictionary: Record<Dictionaries.DictionaryValue<'security'>, string>,
  securityGuarantor?: NonNullable<ClientTypes.IClientMicrocredit['security']>['list'],
): TRow => {
  if (!securityGuarantor?.length) {
    return;
  }

  return ['Вид обеспечения', securityGuarantor.map((documentId) => dictionary[documentId]).join(', ')];
};

export const securityGuarantorDescriptionText = (
  description?: NonNullable<ClientTypes.IClientMicrocredit['security']>['description'],
): TRow => (description ? ['Описание обеспечения', description] : undefined);

export const commentZaimyText = (
  description?: ClientTypes.IClientMicrocredit['customerRequirements']['description'],
): TRow => (description ? ['Тип заемщика', description] : undefined);

export const documentsToSecurityText = (
  securities: string[],
  dictionary: Record<Dictionaries.DictionaryValue<'security'>, string>,
): TRow => {
  const values = securities.map((key) => dictionary[key]);

  return ['Залог (обеспечение)', values.join(' / ') || 'не требуется'];
};

export const getDocumentsText = (
  dictionary: Record<Dictionaries.DictionaryValue<'microCreditsDocuments'>, string>,
  documents: ClientTypes.IClientMicrocredit['customerRequirements']['documents'],
): string[] => {
  if (!documents?.length || !dictionary) {
    return [];
  }

  return documents.flatMap((document) => document.list.map((documentId) => dictionary[documentId]));
};

export const requiredDocumentsText = (
  documents: ClientTypes.IClientMicrocredit['customerRequirements']['documents'],
  dictFields: Record<Dictionaries.DictionaryValue<'microCreditsDocuments'>, string>,
): TRow => {
  if (!documents?.length) {
    return undefined;
  }

  const items = getDocumentsText(
    dictFields,
    documents.filter(({ required }) => required),
  );

  if (!items.length) {
    return undefined;
  }

  return ['Необходимые документы', items.join(' / ')];
};

export const commentDocumentsText = (
  documents: ClientTypes.IClientMicrocredit['customerRequirements']['documents'],
): TRow => {
  const comments = documents
    ?.filter(({ required, comment }) => required && trim(comment))
    .map(({ comment }) => comment)
    .join(' / ');

  return comments ? ['Пояснение к документам', comments] : undefined;
};

export const optionalDocumentsText = (
  documents: ClientTypes.IClientMicrocredit['customerRequirements']['documents'],
  dictFields: Record<Dictionaries.DictionaryValue<'microCreditsDocuments'>, string>,
): TRow => {
  const documentOptional = documents?.filter(({ required }) => !required);
  const items = getDocumentsText(dictFields, documentOptional);
  const { comment } = documentOptional?.[0] || {};

  if (!items.length) {
    return undefined;
  }

  return ['Дополнительные документы', items.join(' / ') + (comment ? `, ${comment}` : '')];
};

export const ageText = (cReqs: ClientTypes.IClientMicrocredit['customerRequirements']): TRow => {
  const startAge = cReqs.age;
  const endAge = Math.min(cReqs.femaleAgeAtRepayment, cReqs.manAgeAtRepayment);

  // eslint-disable-next-line @typescript-eslint/init-declarations
  let text;

  if (startAge === endAge) {
    text = startAge;
  } else if (endAge === 0) {
    text = `от ${startAge}`;
  } else {
    text = `от ${startAge} до ${endAge}`;
  }

  const plural = pluralize(endAge || startAge, 'год', 'года', 'лет');

  return ['Возраст заемщика', `${text} ${plural}`];
};

export const guarantorText = (guarantor = false): TRow => {
  const value = guarantor ? 'да' : 'нет';

  return ['Требуется поручитель', value];
};
