import type { SyntheticEvent } from 'react';

import type { IClientMicrocredit } from '@src/@types/clienttypes';
import { sendOpenReviewsEvent } from '@src/helpers/analyticsEvents';

type Props = {
  href: string;
  reviewsCount: string;
  name: string;
  organizationName: string;
  advertising: IClientMicrocredit['advertising'];
};

export const ReviewsLink = ({ href, reviewsCount, organizationName, name, advertising }: Props) => {
  const handleReviewsClick = (event: SyntheticEvent) => {
    event.stopPropagation();

    const source = `search|position_${advertising.position}`;
    const eventLabel = `${organizationName}|${name}|${advertising.offerId}|${source}`;

    const eventActionType = 'Посмотреть';
    sendOpenReviewsEvent(eventLabel, eventActionType);
  };

  return (
    <a onClick={handleReviewsClick} target="_blank" rel="noreferrer" href={href}>
      {reviewsCount}
    </a>
  );
};
