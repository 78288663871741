import React from 'react';

const DocumentIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
    <path
      d="M12.3448 4.345L9.98817 1.98833C9.67567 1.67583 9.2515 1.5 8.80984 1.5H2.83317C1.91234 1.5 1.1665 2.24583 1.1665 3.16667V14.8333C1.1665 15.7542 1.91234 16.5 2.83317 16.5H11.1665C12.0873 16.5 12.8332 15.7542 12.8332 14.8333V5.52333C12.8332 5.08167 12.6573 4.6575 12.3448 4.345V4.345Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8332 5.66667H9.49984C9.03984 5.66667 8.6665 5.29333 8.6665 4.83333V1.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.6665 8.16699H8.6665"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.6665 10.667H8.6665"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.6665 13.167H7.27484"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DocumentIcon;
