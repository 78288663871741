import type { AdditionalServices } from '@src/@types/microcredits';
import { AdditionalService } from 'src/components/ProductList/components/CardV2/components/AdditionalConditions/components/AdditionalService';
import { AdditionalServiceUnknown } from 'src/components/ProductList/components/CardV2/components/AdditionalConditions/components/AdditionalServiceUnknown';

type Props = {
  additionalServices: AdditionalServices;
};

export const AdditionalConditions = ({ additionalServices }: Props) => {
  const hasAdditionalServices = Boolean(additionalServices.list.length);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasAdditionalServices ? (
        additionalServices.list.map((service) => <AdditionalService key={service.type} service={service} />)
      ) : (
        <AdditionalServiceUnknown />
      )}
    </>
  );
};
