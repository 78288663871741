export const CARD_DETAILS_ACCORDION_DEFAULT_FIELDS_STATE = {
  conditions: true,
  prolongation: true,
  penalty: true,
  repaymentsConditions: true,
  support: true,
  customerRequirements: true,
  generalInfo: true,
  documents: true,
};
