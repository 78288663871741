import type { ReactNode } from 'react';

import { Bubble, Percent, RubleExchange, Shield, ShieldLock, ThreeDots } from '@sravni/react-icons';

import type { AdditionalServicesType } from '@src/@types/microcredits';

const SERVICE_ICONS_MAP: Record<AdditionalServicesType, ReactNode> = {
  insurance: <ShieldLock />,
  fee: <Percent />,
  other: <ThreeDots />,
  informing: <Bubble />,
  prolongation: <RubleExchange />,
  'legal services': <Shield />,
};

export const getServiceIcon = (serviceType: AdditionalServicesType): ReactNode => SERVICE_ICONS_MAP[serviceType];
