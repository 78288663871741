export const getEventActionValue = (key: string, label: string) => {
  if (key === 'phone') {
    return `Телефон|${label}`;
  }

  if (key === 'email') {
    return `Почта|${label}`;
  }

  return label;
};
