import type { Repayment, RepaymentConditions } from '@src/@types/clienttypes';
import { Currency } from '@src/@types/currency';
import { getAmountString } from '@src/helpers/common';

export const calculateOverpayment = (
  rateMax: number,
  term: number,
  amount: number,
  currency: Currency = Currency.RUB,
) => {
  const annualRate = rateMax * 365;
  const overpayment = ((amount * (annualRate / 100)) / 365) * term;

  return {
    overpayment,
    overpaymentText: getAmountString(overpayment, currency),
  };
};

const REPAYMENT_OPTIONS: Record<Repayment, string> = {
  free: 'Бесплатное',
  paid: 'Платное',
};

export const translateRepaymentCondition = (repaymentConditions: RepaymentConditions) =>
  (repaymentConditions?.isEarlyRepayment &&
    repaymentConditions?.repayment &&
    REPAYMENT_OPTIONS[repaymentConditions.repayment]) ||
  '—';

const NO_ADDITIONAL_CONDITIONS_BADGE_TEXT = 'Займ без доп услуг';
const ZERO_PERCENT_BADGE_TEXT = '0% для новых клиентов';
const FIRST_LOAN_BENEFIT = 'Первый займ 0%';

export const getBadgeList = (hasAdditionalConditions: boolean, benefits: string[] | undefined): string[] => {
  const badges: string[] = [];

  if (!hasAdditionalConditions) {
    badges.push(NO_ADDITIONAL_CONDITIONS_BADGE_TEXT);
  }

  if (benefits?.includes(FIRST_LOAN_BENEFIT)) {
    badges.push(ZERO_PERCENT_BADGE_TEXT);
  }

  return badges;
};
