import cn from 'classnames';
import type { SyntheticEvent } from 'react';

import { Typography } from '@sravni/react-design-system';
import { Icon } from '@sravni/react-design-system/lib/Icon';

import { sendSupportItemClickEvent } from '@src/helpers/analyticsEvents';

import styles from '../../styles.module.scss';
import type { SupportItem } from '../../utils/getSupportData';

import { getEventActionValue } from './utils';

type Props = {
  support: SupportItem[];
  eventLabel: string;
};

const { Link, Text } = Typography;

export const Support = ({ support, eventLabel }: Props) => {
  const handleIconClick = (event: SyntheticEvent, key: string, label: string) => {
    event.stopPropagation();

    const eventActionValue = getEventActionValue(key, label);
    sendSupportItemClickEvent(eventLabel, eventActionValue);
  };

  return (
    <div className={cn(styles.threeColumnsWrapper, styles.oneRow)}>
      {support.map(({ key, link, label, icon }) => (
        <div key={label} className={styles.cardItemWithIcon}>
          <div className={styles.content}>
            <Text className="h-color-D60" size={12}>
              {label}
            </Text>
            {link && (
              <Link
                onClick={(event) => handleIconClick(event, key, label)}
                target="_blank"
                rel="noopener"
                className={styles.link}
                href={link}
              >
                <Icon icon={icon} size={20} />
              </Link>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
